(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/pix-button.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/pix-button.js');
"use strict";

const {
  CouponChoiceButton
} = svs.components.tipsen.couponChoiceButton;
const PixButton = _ref => {
  let {
    disabled,
    onClick,
    isShowingPix
  } = _ref;
  return React.createElement(CouponChoiceButton, {
    "aria-expanded": isShowingPix,
    className: "coupon-toolbox-pix-button",
    icon: "pix",
    isDisabled: disabled,
    isSelected: isShowingPix,
    onClick: onClick
  }, "Fyll i med PIX!");
};
setGlobal('svs.components.tipsen.couponChoiceButton.PixButton', PixButton);

 })(window);