(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/odds-button.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/odds-button.js');
"use strict";

const {
  CouponChoiceButton
} = svs.components.tipsen.couponChoiceButton;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  getRelativeUrl
} = svs.components.tipsen.couponChoiceButton;
const OddsButton = _ref => {
  let {
    routesMatch,
    toParams
  } = _ref;
  const buttonText = 'Odds';
  return React.createElement(TipsenLink, {
    className: "coupon-toolbox-odds-button",
    to: {
      pathname: getRelativeUrl(routesMatch.url, TipsenModuleGameUrl.ODDS),
      search: "?".concat(toParams)
    }
  }, React.createElement(CouponChoiceButton, {
    icon: "odds"
  }, buttonText));
};
setGlobal('svs.components.tipsen.couponChoiceButton.OddsButton', OddsButton);

 })(window);