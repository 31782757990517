(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/get-relative-url.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/get-relative-url.js');
"use strict";

const getRelativeUrl = function (url) {
  for (var _len = arguments.length, paths = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    paths[_key - 1] = arguments[_key];
  }
  return [url.endsWith('/') ? url.slice(1) : url, ...paths].join('/');
};
setGlobal('svs.components.tipsen.couponChoiceButton.getRelativeUrl', getRelativeUrl);

 })(window);