(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/clear-button.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/clear-button.js');
"use strict";

const {
  CouponChoiceButton
} = svs.components.tipsen.couponChoiceButton;
const ClearButton = _ref => {
  let {
    isCouponEmpty,
    onClearCoupon
  } = _ref;
  return React.createElement(CouponChoiceButton, {
    className: "coupon-toolbox-clear-button",
    "data-testid": "clear-button",
    icon: "dustbin",
    isDisabled: isCouponEmpty,
    onClick: onClearCoupon
  }, "Rensa");
};
setGlobal('svs.components.tipsen.couponChoiceButton.ClearButton', ClearButton);

 })(window);