(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/settings-button.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/settings-button.js');
"use strict";

const {
  CouponChoiceButton
} = svs.components.tipsen.couponChoiceButton;
const SettingsButton = _ref => {
  let {
    onClick,
    isShowingSettings
  } = _ref;
  return React.createElement(CouponChoiceButton, {
    className: "coupon-toolbox-settings-button",
    icon: "settings",
    isSelected: isShowingSettings,
    onClick: onClick
  }, "Anpassa");
};
setGlobal('svs.components.tipsen.couponChoiceButton.SettingsButton', SettingsButton);

 })(window);