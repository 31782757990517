(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/game-guide-button.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/game-guide-button.js');
"use strict";

const {
  CouponChoiceButton
} = svs.components.tipsen.couponChoiceButton;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  getRelativeUrl
} = svs.components.tipsen.couponChoiceButton;
const GameGuideButton = _ref => {
  let {
    routesMatch,
    toParams
  } = _ref;
  return React.createElement(TipsenLink, {
    className: "coupon-toolbox-guide-button",
    to: {
      pathname: getRelativeUrl(routesMatch.url, TipsenModuleGameUrl.GAME_GUIDE),
      search: "?".concat(toParams)
    }
  }, React.createElement(CouponChoiceButton, {
    icon: "spelguide"
  }, "Spelguide"));
};
setGlobal('svs.components.tipsen.couponChoiceButton.GameGuideButton', GameGuideButton);

 })(window);